<template>
    <!--eslint-disable no-irregular-whitespace-->
    <project>
        <template v-slot:project-head>
            <h2 class="project-title">Designing Covers for thrilling podcasts</h2>
            <div class="project-intro">
                <p>
                    For the podcast platform <a class="hyperlink" href="#">Audio Now</a>, I’ve designed serveral podcast covers and lead the redesign of the renouned true crime podcast <a class="hyperlink" href="https://www.podcastfabrik.de/podcasts/verbrechen/">Verbechen von Nebenan</a>.
                </p>
            </div>
            <ul class="project-tag-list tx-midgrey tx-small">
                <li>Cover Design</li>
                <li>Logo Design</li>
                <li>Branding</li>
            </ul>
        </template>
        <template v-slot:project-content>

            <figure class="img--full" style="background-color: #dec2c2">
                <dynamic-image
                    is-rounded
                    src="audio-alliance-cover_v3.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
            </figure>

            <div class="podcast-grid">
                <div class="text-block">
                    <h2 class="tx-white">Verbrechen von Nebenan</h2>
                        <p>
                            Philipp Fleiters <a class="hyperlink" href="https://www.instagram.com/philippfleiter">@philippfleiter</a> Podcast “Verbrechen von Nebenan” is one of the most successful true crime podcasts in Germany.
                            With his cooperation with Audio Now and newly shot press photos, I was comissioned to design an evolutionary brand logo as well as
                            a new podcast cover.
                        </p>
                        <p>
                            Firstly, we explored the visual space of the true crime genre and analysed the visual vocabulary of the genre beyond podcasts.
                            Aside from stylized depictions of blood, weapons and crime scenes, we identified a predominantly dark red, black and white color scheme.
                        </p>
                        <p>
                            Based on the podcasts title, we explored various concepts of “crime next door” for instance visualized with a hunter fence, common to german suburbia.
                        </p>
                        <p>
                            Finally, we decided on the concept crime house — an ordinary house, drenched in blood, foreshadowing the atrocities next door. This iconic symbol is enclosed by
                            a loud and tabloidesk font, in reference to alarming news headlines.
                        </p>
                    <a class="btn" href="https://audionow.de/podcast/f78a58fa-e496-4e4e-8175-54d53a8aede7" no-refer>Listen to Verbrechen von Nebenan</a>
                </div>

                <dynamic-image
                    class="cover--final"
                    is-rounded
                    src="projects/audio-alliance/verbrechen-nebenan/vvn-final.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>

                <dynamic-image
                    class="cover--highlight"
                    is-rounded
                    src="projects/audio-alliance/verbrechen-nebenan/vvn-house-final.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>

                <dynamic-image
                    class="cover"
                    v-for="i in 7"
                    is-rounded
                    :key="i"
                    :src="`projects/audio-alliance/verbrechen-nebenan/ideas/vvn-idea-${i}.png`"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>

                <dynamic-image
                    class="cover"
                    v-for="i in 15"
                    is-rounded
                    :key="i"
                    :src="`projects/audio-alliance/verbrechen-nebenan/concepts/vvn-concept-${i}.png`"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>

                <dynamic-image
                    class="cover"
                    v-for="i in 4"
                    is-rounded
                    :key="i"
                    :src="`projects/audio-alliance/verbrechen-nebenan/house/vvn-house-${i}.png`"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>

                <dynamic-image
                    class="cover"
                    v-for="i in 7"
                    is-rounded
                    :key="i"
                    :src="`projects/audio-alliance/verbrechen-nebenan/semifinal/vvn-semifinal-${i}.png`"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
            </div>

            <div class="podcast-grid">
                <div class="text-block">
                    <h2 class="tx-white">Chasing Marsalek — the Ubiquitous Unkown</h2>
                    <p>
                        The podcast tries to follow Jan Marsaleks steps, the mastermind behind one of the greatest billion dollar frauds — the wirecard scandal. Unlike other white collar crime podcasts,
                        focused on the economical perspective, the podcast “Chasing Marsalek” draws an in-dept profile of the tech companies manager, his deception and his escape.
                    </p>
                    <p>
                        The podcast cover is based on the ubiquitous police portraits of Marsalek, that were on displayed worldwide up to the last subway station.
                        In contrast, Maraslek has always avoided public appearences. There are only few press portraits of him. The perfect profile to disappear?
                    </p>
                    <a class="btn" href="https://audionow.de/podcast/dc3bc878-7288-4ebb-96cc-46986f2ebc1e" no-refer>Listen to Chasing Marsalek</a>
                </div>

                <dynamic-image
                    class="cover--final"
                    is-rounded
                    src="projects/audio-alliance/chasing-marsalek/chasing-marsalek-final.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>

                <dynamic-image
                    class="cover"
                    v-for="i in 8"
                    is-rounded
                    :key="i"
                    :src="`projects/audio-alliance/chasing-marsalek/typography/typography-${i}.png`"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>

                <dynamic-image
                    class="cover"
                    v-for="i in 7"
                    is-rounded
                    :key="i"
                    :src="`projects/audio-alliance/chasing-marsalek/marsalek/marsalek-${i}.png`"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>

                <dynamic-image
                    class="cover"
                    v-for="i in 5"
                    is-rounded
                    :key="i"
                    :src="`projects/audio-alliance/chasing-marsalek/concepts/chasing-marsalek-concept-${i}.png`"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>

                <dynamic-image
                    class="cover"
                    v-for="i in 9"
                    is-rounded
                    :key="i"
                    :src="`projects/audio-alliance/chasing-marsalek/final/chasing-marsalek-echo-${i}.png`"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
            </div>

            <div class="podcast-grid">
                <div class="text-block">
                    <h2 class="tx-white">The Iconic Song</h2>
                    <p>
                        The first season of The Iconic Song digs deeper into the story of Scorpions’ 1991 worldwide hit anthem ‘Wind Of Change’.
                        The Iconic Song series tells the stories behind some of the most influential songs in popular music.
                        For the cover I explored iconic photographs of the peaceful revolution as well as the visual identity of the Scorpions.
                    </p>
                    <a class="btn" href="https://audionow.de/podcast/eb0b3aef-0dfa-4093-a3f6-a9d8803841eb" no-refer>Listen to The Iconic Song</a>
                </div>

                <dynamic-image
                    class="cover--final"
                    is-rounded
                    src="projects/audio-alliance/iconic-song/iconic-song-final.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>



                <dynamic-image
                    class="cover"
                    v-for="i in 5"
                    is-rounded
                    :key="i"
                    :src="`projects/audio-alliance/iconic-song/typography/is-typography-${i}.png`"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>

                <dynamic-image
                    class="cover"
                    v-for="i in 12"
                    is-rounded
                    :key="i"
                    :src="`projects/audio-alliance/iconic-song/concepts/is-concept-${i}.png`"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>

                <dynamic-image
                    class="cover"
                    v-for="i in 9"
                    is-rounded
                    :key="i"
                    :src="`projects/audio-alliance/iconic-song/first-draft/is-first-draft-${i}.png`"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>

                <dynamic-image
                    class="cover"
                    v-for="i in 2"
                    is-rounded
                    :key="i"
                    :src="`projects/audio-alliance/iconic-song/semifinal/is-semifinal-${i}.png`"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
            </div>

            <div class="podcast-grid">
                <div class="text-block text-block-full">
                    <h2 class="tx-white">Additional Podcast Covers</h2>
                    <p>
                        In my collaboration with Audio Alliance, I have designed additional podcast covers also worth noting.
                    </p>
                </div>
                <dynamic-image
                    class="cover cover--highlight cover--additional"
                    is-rounded
                    :key="i"
                    :src="`projects/audio-alliance/herzfarben/herzfarben-final.png`"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <div class="text-block text-block-float">
                    <h2 class="tx-white">Herzfarben</h2>
                    <p>
                        The Podcast by the famous german trans actor Brix Schaumburg is a diverse podcast about everyday life in society, politics, culture.
                        For the cover I’ve explored vibrant color gradients to match Brix’ extravagance.
                    </p>
                    <a class="btn" href="https://audionow.de/podcast/b2d8b945-f37a-4a01-891c-ed1d9c40e8a1" no-refer>Listen to Herzfarben</a>
                </div>
                <dynamic-image
                    class="cover cover--highlight cover--additional"
                    is-rounded
                    :key="i"
                    :src="`projects/audio-alliance/stunde-null/stunde-null-final.png`"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <div class="text-block text-block-float">
                    <h2 class="tx-white">Die Stunde Null</h2>
                    <p>
                        Then RTL Mediengruppe Editor-in-chief Tanit Koch co-hosts Horst von Buttlar, Capital Magazin Chief Editor in a business and economy podcast about "the zero hour" during
                        the COVID-19 global pandemic.
                    </p>
                    <a class="btn" href="https://audionow.de/podcast/eb511796-b4df-4ac1-bc27-b67471d97f58" no-refer>Listen to Die Stunde Null</a>
                </div>
                <dynamic-image
                    class="cover cover--highlight cover--additional"
                    is-rounded
                    :key="i"
                    :src="`projects/audio-alliance/wunderleben/wunderleben-final.png`"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <div class="text-block text-block-float">
                    <h2 class="tx-white">Wunder leben</h2>
                    <p>
                        Cyber-Punk Couple Edith and Eric Stehfest explore deep human connections in their very unique spiritual and magical way: mutual love, addictions, abuse, life and death.
                    </p>
                    <a class="btn" href="https://audionow.de/podcast/ffca9c83-87c0-4f1d-a7c5-8b8d69cc1de4" no-refer>Listen to Wunder leben</a>
                </div>
            </div>

            <!-- <div class="container">
                <div class="content-grid">
                    <div class="center">

                        <video
                            loop
                            muted
                            autoplay
                            width="800"
                            height="800">
                            <source :src="require(`@/assets/vid/topada-audio-alliance-iconic-songs.mp4`)" type="video/mp4">
                            Your browser does not support the video element. Kindly update it to latest version.
                        </video >

                    </div>
                </div>
            </div> -->
        </template>
    </project>
</template>

<script>
import Project from "@/layouts/Project.vue";
import DynamicImage from "@/components/DynamicImage";

export default {
    name: "freelance-junior",
    components: {
        Project,
        DynamicImage,
    }
}
</script>

<style lang="scss" scoped>
    .grid {
        display: grid;
        grid-gap: var(--vspace);
        grid-template-columns: repeat(4, 1fr);
        margin-bottom: 10vh;
    }

    @media (min-width: 70em) {
        .content-grid {
            display: grid;
            grid-gap: var(--vspace);
            margin-bottom: 10vh;
            grid-template-columns: repeat(4, 1fr);
        }

        .center {
            grid-column: 2 / 4;
        }
    }

    .podcast-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-auto-flow: dense;
        padding: 1rem;
        gap: 1rem;
    }

    .podcast-grid + .podcast-grid {
        margin-top: 8rem;
    }

    .cover {
        &--final,
        &--highlight {
            grid-column: span 2;
            grid-row: span 2;
        }

        &--final {
            box-shadow:
                0rem 0rem 5rem 1rem hsla(0, 0%, 0%, 0.5),
                0rem 0rem 10rem 5rem hsla(0, 0%, 0%,0.15);
            z-index: 10;
        }
    }

    .text-block,
    .text-block-float {
        grid-column: span 2;
        padding-bottom: 2rem;
    }

    @media (min-width: 46rem) {
        .podcast-grid {
            grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
            padding: 4rem;
        }

        .cover {
            grid-column: span 1;
            grid-row: span 1;

            &--highlight {
                grid-column: span 2;
                grid-row: span 2;
            }
        }

        .text-block {
            grid-column: 1 / -1;
            padding-right: 2rem;
        }

        .text-block-float
        {
            grid-column: -3 / -1;
            grid-row: span 2;
        }
    }

    @media (min-width: 112rem) {
        .cover {
            &--final {
                grid-column: span 3;
                grid-row: 2 / 5;
            }

            &--highlight {
                grid-column: span 2;
                grid-row: span 2;
            }

            &--additional {
                grid-column: span 2;
                grid-row: 3 / 4;
            }
        }

        .text-block {
            grid-column: 1 / 3;
            grid-row: 1 / 3;
            padding-right: 2rem;
        }

        .text-block-float {
            grid-column: span 2;
            grid-row: 4 / 5;
        }
    }


  .inset-200 {
      padding: 2rem;
  }

  .btn {
    background-color: var(--color-darkgrey-200);
    border-radius: 2rem;
    display: inline-flex;
    padding: 0.75rem 2rem;
    transition: all 0.2s ease-in-out;
    font-size: var(--tx-clamp-small);
    font-weight: 500;
    letter-spacing: 0.025rem;

    &:hover {
        background-color: var(--color-darkgrey-300);
    }
  }
</style>