
import { createApp } from "vue";
import router from "./router"
import App from "./App.vue"

// createApp(App).use(router).mount("#app")

const app = createApp(App);

app.config.globalProperties.$isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

app.use(router);

app.mount("#app");