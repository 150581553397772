<template>
    <!-- <div class="fixed">hallo mama</div> -->
    <mainNavigation
      is-scroll-fixed
      :is-scrolling-up="isScrollingUp"
      :is-below-fold="isBelowFold" />
    <router-view />
</template>

<script>
import MainNavigation from "@/components/MainNavigation";

export default {
  name: "App",
  components: {
    MainNavigation,
  },
  data() {
    return {
      scrollPos: 0,
      isScrollingUp: true,
      isBelowFold: false,
      time: null,
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
        if(this.time)
        {
          clearTimeout(this.time);
        }

        if(!document.body.classList.contains('disable-hover')) {
            document.body.classList.add('disable-hover');
        }

        this.time = setTimeout(() => {
            document.body.classList.remove('disable-hover');
        }, 100);

        const clientRect = document.body.getBoundingClientRect();
        this.isScrollingUp = clientRect.top > this.scrollPos;
        this.isBelowFold = clientRect.top * -1 > window.innerHeight;
        this.scrollPos = clientRect.top;
    },

  },
}
</script>
<style lang="scss">
@import "@/scss/_variables.scss";
@import "@/scss/_typography.scss";

@import url("https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/hk-grotesk.min.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@600&display=swap");


html,
body {
  min-height: -webkit-fill-available;
  background-color: var(--color-darkgrey);
  color: white;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@media (prefers-color-scheme: light) {
  // html,
  // body {
  //     background-color: white;
  //     color: black;
  // }
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
  padding: 0;
}

#app {
  position: relative;
}

.container {
  max-width: 120rem;
  width: 90%;
  margin: 0 auto;
}

.container-fluid {
  margin: 0 auto;
  padding: 2rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.nav-row {
  display: grid;
  grid-template-columns: 1fr auto;
}

.col-6 {
  flex: 0 0 50%;
}

.nav-brand {
  font-family: "Montserrat Alternates";
  font-weight: 500;
  font-size: 1.5rem;

  a {
    transition: all 0.3s var(--ease-out-quad);

    &:hover {
      color: var(--color-accent-light);
    }
  }
}

.nav-links {
  padding: 0.5rem 0;
  margin: 0;

  li {
    padding-left: 1.5rem;
    transition: all 0.3s var(--ease-out-quad);

    &:hover {
      opacity: 0.5;
    }
  }
}

@media (min-width: 25rem) {
  .nav-links {
    padding: 0.5rem 0;
    margin: 0;

    li {
      padding: 0 1rem;
    }
  }
}

.h-100 {
  height: 100%;
}

.py-150 {
  padding: 1.5rem 0;
}

.d-flex {
  display: flex;
}

.align-center {
  align-self: center;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.justify-content-between {
  justify-content: space-between;
}

.rounded-small {
  border-radius: 0.25rem;
}

.overflow-hidden {
  overflow: hidden;
}

@each $size, $length in $spacers {
  .mt-#{$size} {
    margin-top: $length !important;
  }
  .mr-#{$size} {
    margin-right: $length !important;
  }
  .mb-#{$size} {
    margin-bottom: $length !important;
  }
  .ml-#{$size} {
    margin-left: $length !important;
  }

  .my-#{$size} {
    margin-top: $length !important;
    margin-bottom: $length !important;
  }

  .mx-#{$size} {
    margin-left: $length !important;
    margin-right: $length !important;
  }
}

// h2 {
//   font-size: 1.2rem;
//   font-size: clamp(1.2rem, -0.875rem + 8.333vw, 1.3rem);
//   line-height: 1.5rem;
//   font-weight: 700;
//   margin-top: 0;
//   margin-bottom: 0.25rem;
// }
//
// p {
//   color: hsl(0, 0%, 0%);
//   font-size: clamp(1rem, -0.875rem + 8.333vw, 1.125rem);
//   line-height: 1.5rem;
//   font-weight: 500;
//   margin-top: 0;
//   margin-bottom: 0.75rem;
// }

/* xs */
@media (max-width: 25em) {
  .d-xs-none {
    display: none;
  }
}

/* sm */
@media (min-width: 25em) {
  .d-sm-flex {
    display: flex;
  }
}

// Disable Hover on Scroll Class
// Add these helpers to your utlity.css
// http://www.thecssninja.com/javascript/pointer-events-60fps
.disable-hover,
.disable-hover * {
    pointer-events: none !important;
}

.fixed {
  position: fixed;
  background: red;
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
}

</style>