<template>
  <div class="about">
    <header>
      <mainNavigation />
    </header>

    <div class="container">
        <div class="about-grid">
            <div class="content fade-in-up">
                <h1>Jonas Köpfer</h1>
                <!--eslint-disable no-irregular-whitespace-->
                <p>I’m a Designer and Developer focused on delightful Interaction Design, working as a freelancer in Frankfurt am Main.</p>
                <p>
                    I graduated at the Interface Design Programme at the
                    <a class="hyperlink" href="https://design.fh-potsdam.de/interfacedesign">University of Applied Sciences, Potsdam</a>,
                    I was enrolled in the Interaction Design Programme at
                    <a class="hyperlink" href="https://mau.se/en/study-education/programme/tgide/">Malmö University, Sweden</a>
                    and in the Media Studies Programme at <a class="hyperlink" href="https://medienwissenschaft.uni-bayreuth.de/">University of Bayreuth</a>.
                </p>
                <p>
                    Currently I work as a freelancer primarily for <a class="hyperlink" href="https://freelancejunior.de/">Freelance Junior</a> and
                    <a class="hyperlink" href="https://audioalliance.de/">Audio Alliance</a> amongst other clients.
                    Previously I did interactive prototypes and built design systems for renowned asset managements with
                    <a class="hyperlink" href="https://www.fintechcube.com/de/home">fintechcube</a> and value-based brand communication strategies with
                    <a class="hyperlink" href="https://short-cuts.de/">short cuts</a> in Berlin. I collaborated with
                    <a class="hyperlink" href="https://www2.deloitte.com/global/en/pages/strategy-operations/monitor-deloitte/topic/monitor-deloitte.html">Monitor Deloitte</a>,
                    <a class="hyperlink" href="https://www.dms-logistik.de/">DMS Logistik</a>,
                    <a class="hyperlink" href="https://www.kuk.de/">KREBS+KIEFER</a>,
                    <a class="hyperlink" href="https://www.bsh-group.com/">BSH Hausgeräte</a> and <a class="hyperlink" href="https://www.sirion-biotech.com/">Sirion Biotech</a>.
                </p>
                <p>
                    I’ve learned Japanese during an exciting year abroad in Tōkyō, I’m a vegan chef and a passionate <a class="hyperlink" href="https://instagram.com/canjon.cc">Road Cyclist</a>.
                </p>
            </div>

            <div class="education fade-in-up">
                <h2>Education</h2>
                <ul>
                    <li>
                        <h3>Interface Design B.A.</h3>
                        <p>University of Applied Science Potsdam</p>
                        <p class="tx-small tx-midgrey">2014—2020</p>
                    </li>
                    <li>
                        <h3>Interaction Design · Erasmus+</h3>
                        <p>Malmö University</p>
                        <p class="tx-small tx-midgrey">2017</p>
                    </li>
                    <li>
                        <h3>Media Studies B.A.</h3>
                        <p>University of Bayreuth</p>
                        <p class="tx-small tx-midgrey">2010—2014</p>
                    </li>
                </ul>
            </div>

            <div class="experience fade-in-up">
                <h2>Experience</h2>
                <ul>
                    <li>
                        <p></p>
                        <h3>Freelance Junior</h3>
                        <p>Hamburg</p>
                        <p class="tx-small tx-midgrey">2014—2020</p>
                    </li>
                    <li>
                        <h3>fintechcube</h3>
                        <p>Berlin</p>
                        <p class="tx-small tx-midgrey">2017</p>
                    </li>
                    <li>
                        <h3>SHORT CUTS design + kommunikation</h3>
                        <p>Berlin</p>
                        <p class="tx-small tx-midgrey">2010—2014</p>
                    </li>
                </ul>
            </div>

        </div>
    </div>
  </div>
</template>

<script>
    import MainNavigation from "@/components/MainNavigation";

    export default {
        name: "Privacy",
        components: {
            MainNavigation,
        },
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/_variables.scss";
    @import "@/scss/_animations.scss";

    .about-grid {
        display: grid;
        gap: ($spacer * 6) ($spacer * 4);
        // grid-template-columns: repeat(2, minmax(20rem, 1fr) );
    }

    @media (min-width: 40rem) {
        .about-grid {
            grid-template-columns: repeat(2, minmax(20rem, 1fr) );
            grid-template-rows: 80vh 1fr
        }
    }

    .content {
        align-self: center;
    }



    li {
        margin-bottom: 1rem;

        h1, h2, h3, p {
            margin: 0;
        }
    }


</style>