<template>
    <project>
        <template v-slot:project-head>
            <h2 class="project-title">Tobias Runge</h2>
            <div class="project-intro">
                <p>
                    Tobias Runge <a class="hyperlink" href="https://www.instagram.com/runge_tobias" rel="noopener" target="_blank">@tobias_runge</a> is an aspiring carpenter artist and wood-worker. For Tobias I designed a unique signet to brand his creations, inspired initials by the artists of the <a class="hyperlink" href="https://en.m.wikipedia.org/wiki/Wiener_Werkst%C3%A4tte" rel="noopener" target="_blank">Wiener Werkstätte</a>.
                </p>
            </div>
            <ul class="project-tag-list tx-midgrey tx-small">
                <li>Branding</li>
                <li>Logo Design</li>
            </ul>
        </template>
        <template v-slot:project-content>
            <figure class="img--full" style="background-color: #161221">
                <dynamic-image
                    is-fullscreen
                    src="projects/tobias-runge/tr-cover.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
            </figure>
            <div class="content-grid">
                <div class="block text-block">
                    <h2 class="tx-white">Concepts</h2>
                    <p>
                        Tobias approached me with a rough scan of his signature and asked for my branding advice.
                        Following a brief brand workshop, I developed these monogram concepts.
                    </p>
                    <p>
                        Especially the last mongogram sparked our interest, so I further explored a style loosely connected to the signets of the artists of the Wiener Werkstätte.
                    </p>
                </div>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/tobias-runge/tr-1.png"
                    description="First Explorations"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/tobias-runge/tr-2.png"
                    description="Initial Monogram Variants"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/tobias-runge/ww-1.jpeg"
                    description="Monogram for Artists of the Vienna Secession. Published in the XIV of the catalog for the group, 1902"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <div class="block text-block">
                    <h2 class="tx-white">A strong sign for any occaison</h2>
                    <p>
                        In an continous exchange with Tobias during the design process,
                        I learned that brand would need to be fire-resistant: a branding iron for his wood-working projects had already been planned.
                        Thus, I focused on a well scalable and stolid design language.
                    </p>
                </div>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/tobias-runge/tr-3.jpg"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/tobias-runge/tr-4.jpg"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/tobias-runge/tr-5.jpg"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/tobias-runge/tr-6.jpg"
                    description="Application Examples"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/tobias-runge/tr-7.jpg"
                    description="Branded Wood Tiles for a scavenger hunt"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
            </div>
        </template>
    </project>
</template>

<script>
import Project from "../../layouts/Project.vue";
import DynamicImage from "@/components/DynamicImage";

export default {
    name: "tobias-runge",
    components: {
        Project,
        DynamicImage,
    }
}
</script>

<style lang="scss" scoped>
    .content-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        max-width: 120rem;
        width: 90%;
        margin: 4rem auto;
    }

    .block {
        grid-column: 1 / -1;
    }

    @media (min-width: 46rem) {
        .content-grid {
            grid-template-columns: repeat(4, 1fr);
            grid-template-areas:    ". b1 b1 ."
                                    "b2 b2 b2 b2"
                                    "b3 b3 b3 b4"
                                    ". b5 b5 ."
                                    "b6 b6 b7 b7"
                                    "b8 b8 b8 b8"
                                    "b9 b9 b9 b9"
                                    "b10 b10 . .";
        }

        @for $i from 1 through 50 {
            .block:nth-child(#{$i}) {
                grid-area: b#{$i};
            }
        }
    }

    @media (min-width: 80rem) {
        .content-grid {
            grid-template-columns: repeat(6, 1fr);
            gap: 4rem;
            padding: 4rem;
            grid-template-areas:    "b1 b1 b1 b2 b2 b2"
                                    "b3 b3 b3 b3 b4 b4"
                                    "b5 b5 b6 b6 b7 b7"
                                    "b8 b8 b8 b8 b8 b8"
                                    "b9 b9 b9 b9 b10 b10"
                                    "b9 b9 b9 b9 . .";
        }
    }
</style>