<template>
  <div class="about">
    <header>
      <mainNavigation />
    </header>

    <div class="container">
        <div class="about-grid">
            <div class="content fade-in-up">
                <h1>Imprint</h1>
                <!--eslint-disable no-irregular-whitespace-->
                <p>
                    Jonas Köpfer<br>
                    Röderbergweg 170<br>
                    60385 Frankfurt am Main<br>
                    Germany
                </p>
                <a class="hyperlink" href="mailto:jonas@topada.de">jonas@topada.de</a>
            </div>
        </div>
    </div>
    <main-footer />
  </div>
</template>

<script>
    import MainNavigation from "@/components/MainNavigation";
    import MainFooter from "@/components/MainFooter";

    export default {
        name: "Imprint",
        components: {
            MainNavigation,
            MainFooter,
        },
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/_variables.scss";
    @import "@/scss/_animations.scss";

    .about-grid {
        display: grid;
        gap: ($spacer * 6) ($spacer * 4);
        // grid-template-columns: repeat(2, minmax(20rem, 1fr) );
    }

    @media (min-width: 40rem) {
        .about-grid {
            grid-template-columns: repeat(2, minmax(20rem, 1fr) );
            grid-template-rows: 80vh 1fr
        }
    }

    .content {
        align-self: center;
    }



    li {
        margin-bottom: 1rem;

        h1, h2, h3, p {
            margin: 0;
        }
    }


</style>