<template>
  <footer>
    <section class="container justify-content-between d-flex flex-wrap my-5">
      <div class="nav-brand tx-midgrey align-center">
        <router-link to="/">topada</router-link>
      </div>

      <div>&nbsp;</div>

      <div>
        <ul class="nav-links d-flex flex-column justify-content-between">
            <li><a href="mailto:jonas@topada.de">jonas@topada.de</a></li>
            <li><a href="https://twitter.com/topada">@topada</a></li>
        </ul>
      </div>

      <div>
        <ul class="nav-links d-flex flex-column justify-content-between">
            <li><router-link :to="{ name: 'Imprint' }">imprint</router-link></li>
            <li><router-link :to="{ name: 'Privacy' }">privacy policy</router-link></li>
        </ul>
      </div>
    </section>
  </footer>
</template>

<script>
    export default {
        name: "MainFooter",
    }
</script>

<style lang="scss" scoped>
    footer {
    background: var(--color-blackgrey);
    margin: 0;
    padding: 0;
    overflow: hidden;
    }
</style>