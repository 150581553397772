<template>
    <project>
        <template v-slot:project-title>
            <h1>Breaking Breakpoints</h1>
        </template>
        <template v-slot:project-content>
            <h1>content: breaking-breakpoints</h1>
        </template>
    </project>
</template>

<script>
import project from "../../layouts/Project.vue";

export default {
    name: "breaking-breakpoints",
    components: {
         project,
    }
}
</script>

<style>

</style>