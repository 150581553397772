<template>
    <!--eslint-disable no-irregular-whitespace-->
    <project>
        <template v-slot:project-head>
            <h2 class="project-title">Rebranding State Capital Potsdam</h2>
            <div class="project-intro">
                <p>
                    Rebranding exercise of the Corporate Branding of the State Capital Potsdam, Brandenburg, Germany.
                    <a class="hyperlink" href="http://www.buerobeyrow.de/" rel="noopener" target="_blank">Prof. Matthias Beyrow’s</a> Corporate Identity Class, University of Applied Science, Potsdam.
                </p>
            </div>
            <ul class="project-tag-list tx-midgrey tx-small">
                <li>Branding</li>
                <li>Design Proposal</li>
                <li>Corporate Design</li>
                <li>City Branding</li>
                <li>University Project</li>
            </ul>
        </template>
        <template v-slot:project-content>
            <dynamic-image
                is-fullscreen
                src="potsdam-cover_v2.png"
                alt="Audio Alliance Cover"
                loading="lazy"
                decoding="async"/>

            <div class="content-grid">
                <div class="block text-block">
                    <h2 class="tx-white">Status Quo — the Sausage Logo</h2>
                    <p>
                        The present brand of Potsdam depicts a place of longing — the prussian castle
                        Sanssoucci with grapevines in front. The Logo is presented in Prussian Blue
                        energetically drawn with brush strokes, set in the french renaissance antiqua
                        Bodoni — this is how the city brand for the state capital Potsdam presents
                        itself since early 2000s.
                    </p>
                    <p>
                        However, as we broadly discussed in class, we were not convinced by the desired
                        fresh and dynamic look, rather considered the playful brush strokes as bland.
                        They even reminded us of sausages, which led us to coin it the sausage logo.
                    </p>
                </div>

                <dynamic-image
                    class="block"
                    is-rounded
                    background-color="var(--color-white)"
                    src="projects/potsdam-ci/potsdam-ci-01.png"
                    alt="Audio Alliance Cover"
                    description="Potsdam Logo: castle sanssoucci with brush strokes — the sausage Logo ☺"
                    loading="lazy"
                    decoding="async"/>

                <dynamic-image
                    class="block"
                    is-rounded
                    background-color="var(--color-blackgrey)"
                    src="projects/potsdam-ci/potsdam-ci-02.png"
                    alt="Audio Alliance Cover"
                    description="Potsdam Coat of Arms"
                    loading="lazy"
                    decoding="async"/>

                <div class="block text-block">
                    <h2 class="tx-white">What makes a city brand so special?</h2>
                    <p>
                        Unlike corporate brands, a city brands goal should be to unite different perceptions of the city.
                        For Travelers a city is above all a destination A place to visit, explore and enjoy landmarks and attractions.
                        A city for its citizens, however, is a location. A place to live, work and invest. In addition, a city
                        is also a municipality, organizing and administrating the community life.
                    </p>
                    <!-- <p>
                        The autority and officiality of a municipality
                    </p> -->
                </div>

                <dynamic-image
                    class="block"
                    is-rounded
                    background-color="var(--color-white)"
                    description="In one of my first drafts, I combined various architectural styles of Potsdam."
                    src="projects/potsdam-ci/potsdam-ci-03.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>

                <div class="block text-block">
                    <h2 class="tx-white">A modern heraldic eagle for Potsdam</h2>
                    <p>
                        For my redesign for the city brand of Potsdam, I turned to Potsdam’s heraldic coat of arms. It’s a well respected sign of the city all citizens are already familiar with.
                        It’s an exclusive, sovereign symbol of the city. I greatly simplified the shape of the egale and decluttered the symbol as much as possible.
                    </p>
                </div>

                <dynamic-image
                    class="block"
                    is-rounded
                    background-color="var(--color-blackgrey)"
                    src="projects/potsdam-ci/potsdam-ci-04.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>

                <dynamic-image
                    class="block"
                    is-rounded
                    background-color="var(--color-white)"
                    src="projects/potsdam-ci/potsdam-ci-05.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>

                <dynamic-image
                    class="block"
                    is-rounded
                    background-color="var(--color-blackgrey)"
                    src="projects/potsdam-ci/potsdam-ci-06.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>

                <dynamic-image
                    class="block"
                    is-rounded
                    background-color="var(--color-white)"
                    src="projects/potsdam-ci/potsdam-ci-07.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>

                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/potsdam-ci/potsdam-ci-08.png"
                    alt="Audio Alliance Cover"
                    description="Application examples with vibrant imagery by Potsdam photographer Steven Ritzer @potsdamagram"
                    loading="lazy"
                    decoding="async"/>

                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/potsdam-ci/potsdam-ci-09.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>

                <dynamic-image
                    class="block"
                    is-rounded
                    background-color="var(--color-white)"
                    src="projects/potsdam-ci/potsdam-ci-10.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>

                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/potsdam-ci/potsdam-ci-11.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
            </div>
        </template>
    </project>
</template>

<script>
import project from "@/layouts/Project.vue";
import DynamicImage from "@/components/DynamicImage";

export default {
    name: "freelance-junior",
    components: {
         project,
         DynamicImage,
    }
}
</script>

<style lang="scss" scoped>
    .content-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        max-width: 120rem;
        width: 90%;
        margin: 4rem auto;
    }

    .block {
        grid-column: 1 / -1;
    }

    @media (min-width: 46rem) {
        .content-grid {
            grid-template-columns: repeat(4, 1fr);
            grid-template-areas:    ". b1 b1 ."
                                    ". b2 b2 b3"
                                    ". b2 b2 ."
                                    ". b4 b4 ."
                                    ". b5 b5 ."
                                    ". b6 b6 ."
                                    "b7 b7 b7 b7"
                                    "b8 b8 b9 b9"
                                    "b10 b10 b10 b10"
                                    "b11 b11 b11 b11"
                                    "b12 b12 b14 b14"
                                    "b13 b13 b13 b13";
        }

        @for $i from 1 through 50 {
            .block:nth-child(#{$i}) {
                grid-area: b#{$i};
            }
        }
    }

    @media (min-width: 80rem) {
        .content-grid {
            grid-template-columns: repeat(6, 1fr);
            gap: 4rem;
            padding: 4rem;
            grid-template-areas:    "b1 b1 b2 b2 b3 ."
                                    "b1 b1 b2 b2 . ."
                                    ". . b4 b4 b5 b5 "
                                    "b6 b6 b7 b7 b7 b7"
                                    "b8 b8 b8 b9 b9 b9"
                                    "b10 b10 b10 b10 b10 b10"
                                    "b11 b11 b11 b11 b11 b11"
                                    "b12 b12 b14 b14 b13 b13"
        }
    }
</style>