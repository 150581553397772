<template>
  <div class="baseline-grid">
    <button class="toggle-btn" @click="toggleBaselineGrid">B</button>
    <section>
      <h1>Hysterese</h1>
      <p class="tx-small">19:30 Uhr · Frankfurt am Main</p>
      <p>
      Hysterese, auch Hysteresis („Nachwirkung“; griech. hysteros (ὕστερος) „hinterher, später“), ist eine Änderung der Wirkung, die verzögert gegenüber einer Änderung der Ursache auftritt (z. B. bei der thermostatgesteuerten Heizung die Differenz von Ein- und Ausschalttemperatur).[1] Hysterese charakterisiert ein – bezogen auf die Eingangsgröße (bei der Heizung die Soll-Temperatur) – variant verzögertes Verhalten der bewirkten Ausgangsgröße (bei der Heizung die Ist-Temperatur), welche ihr Maximum bzw. ihr Minimum erreicht hat.
      </p>
      <p>
      Allgemein formuliert handelt es sich bei Hysterese um ein Systemverhalten, bei dem die Ausgangsgröße nicht allein von der unabhängig veränderlichen Eingangsgröße, sondern auch vom vorherigen Zustand der Ausgangsgröße abhängt. Das System kann also – abhängig von der Vorgeschichte – bei gleicher Eingangsgröße einen von mehreren möglichen Zuständen einnehmen. Dieses Verhalten wird auch Pfadabhängigkeit genannt.
      </p>

      <h2>Hysterese-Effekte</h2>
      <p>
      Hysterese tritt bei vielen natürlichen und technischen Vorgängen auf, insbesondere bei der Magnetisierung eines Magneten, in der Regelungstechnik und der Kybernetik.
      </p>
      <p>
      Typisch für Hystereseverhalten ist das Auftreten einer Hystereseschleife, die entsteht, indem man die verursachende Größe zwischen zwei verschiedenen Werten hin und her bewegt. Das bekannteste Phänomen ist das Hystereseverhalten eines Ferromagneten in einem Magnetfeld: Wird ein nicht magnetisierter Ferromagnet einem externen Feld ausgesetzt und dieses danach ausgeschaltet, so behält der Ferromagnet je nach Polung (d. h. Richtung) des externen Feldes eine positive oder negative Magnetisierung. Diese Restmagnetisierung wird als Remanenz bezeichnet.
      </p>
        <h3>Anwendungsbeispiele</h3>
      <p>
      Hysterese tritt bei vielen natürlichen und technischen Vorgängen auf, insbesondere bei der Magnetisierung eines Magneten, in der Regelungstechnik und der Kybernetik.
      </p>
      <p>
      Typisch für Hystereseverhalten ist das Auftreten einer Hystereseschleife, die entsteht, indem man die verursachende Größe zwischen zwei verschiedenen Werten hin und her bewegt. Das bekannteste Phänomen ist das Hystereseverhalten eines Ferromagneten in einem Magnetfeld: Wird ein nicht magnetisierter Ferromagnet einem externen Feld ausgesetzt und dieses danach ausgeschaltet, so behält der Ferromagnet je nach Polung (d. h. Richtung) des externen Feldes eine positive oder negative Magnetisierung. Diese Restmagnetisierung wird als Remanenz bezeichnet.
      </p>
      <p>
  Meist ist eine Hysterese eingebaut, um unerwünschte (z. B. zu schnelle oder zu häufige) Wiederholungen von Ein- und Ausschaltvorgängen zu vermeiden, wenn die gemessene Größe geringe Schwankungen um den Schwellenwert macht. Dazu ist es nötig, dass die obere und die untere Schaltschwelle derart an die jeweiligen Gegebenheiten angepasst werden, dass sich die zu unterdrückenden Schwankungen zwischen diesen beiden Schwellenwerten abspielen. Erst wenn die obere Schaltschwelle überschritten wird, erfolgt das Einschalten, und erst wenn die untere Schaltschwelle unterschritten wird, erfolgt das Ausschalten, und Werte dazwischen führen zu keinen Schaltvorgängen. Die Schaltfunktion kann auch invertierend ausgeführt sein, sodass sich das Ein-/Ausschaltverhalten umkehrt.
      </p>
      <p>
  Ein weiteres Beispiel für einen Schwellwertschalter mit Hysterese ist ein Tiefentladeschutz für eine Batterie. Dieser schaltet die angeschlossenen Verbraucher bei Unterschreiten einer Schwellwertspannung ab. Damit wird die Batterie vor zu starker Entladung geschützt. Durch den Innenwiderstand der Batterie sank deren Klemmenspannung bei Belastung allerdings ab. Fließt kein Strom, steigt die Spannung wieder, und erreicht die Leerlaufspannung. Ohne Hysterese würde das zu einem schnellen und unerwünschten Ein- und Ausschalten führen. Deshalb ist eine etwas höhere Wiedereinschaltspannung vorgegeben. Diese wird erst durch Nachladen der Batterie erreicht.
      </p>
      <p class="tx-extrasmall">loaded in 0.2&nbsp;ms</p>
    </section>
  </div>
</template>

<script>
export default {
  name: "Typography",
  mounted() {
    const paragraphs = document.querySelectorAll("p")

    paragraphs.forEach(el => {
      // keep-together: der/die/das/ein/eine + x
      el.innerHTML = el.innerHTML.replace(/((D|d)er)\s/g, "$1&nbsp;")
      el.innerHTML = el.innerHTML.replace(/((D|d)ie)\s/g, "$1&nbsp;")
      el.innerHTML = el.innerHTML.replace(/((D|d)as)\s/g, "$1&nbsp;")
      el.innerHTML = el.innerHTML.replace(/(ein(e?))\s/g, "$1&nbsp;")

      // keep-together: first 2 words
      el.innerHTML = el.innerHTML.replace(/\.\s(\w+)\s(\w+)/g, ". $1&nbsp;$2")

      // keep-together: last 2 words
      el.innerHTML = el.innerHTML.replace(/(\w+)\s(\w+)\./g, "$1&nbsp;$2.")

      // keep-together: last 3 words of paragraph
      el.innerHTML = el.innerHTML.replace(/(\w+)\s(\w+)\s(\w+)\.$/g, "$1&nbsp;$2&nbsp;$3.")

      // footnote reference [#] to superscript
      el.innerHTML = el.innerHTML.replace(/\[(.)\]/g, "<sup>$1</sup>")

      // abbreviation: thin non-breaking space
      el.innerHTML = el.innerHTML.replace(/(\w\.)\s(\w\.)/g, "$1&#8239;$2")
    });
  },
  methods: {
    toggleBaselineGrid() {
      console.log("toggle!");
      const root = document.documentElement;
      const baseline = parseInt(getComputedStyle(root).getPropertyValue("--dev-baseline"));
      root.style.setProperty("--dev-baseline", baseline ? 0 : 1);
    }
  }
}

</script>

<style lang="scss" scoped>
@import "@/scss/_typography.scss";

html,
body {
  margin: 0;
  padding: 0;
}

section {
  margin: calc(var(--tx-clamp-base) * 2) calc(var(--tx-clamp-base) / 2);
}

@media (min-width: 25rem) {
  section {
    margin: calc(var(--tx-clamp-base) * 2) auto;
    padding: 0 calc(var(--tx-clamp-base));
    max-width: 80ch;
  }
}

.baseline-grid:after {
    opacity: var(--dev-baseline);
    transition: all 0.4s ease-in-out;

    position: absolute;
    width: auto;
    height: 200%;
    overflow: hidden;
    z-index: -100;
    content: '';
    display: block;
    pointer-events: none;
    top: -1px;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: repeat;
    background-size: 100% var(--tx-clamp-base);
    background-position: top left;
    background-color: hsla(250, 100%, 80%, 100%);
    background-image: linear-gradient(hsla(250, 100%, 70%, 100%) 0px, transparent 1px, transparent 100%);
}

.toggle-btn {
  position: absolute;
  right: var(--tx-clamp-base);
  top: var(--tx-clamp-base);
  font-weight: 600;
  font-size: var(--tx-clamp-base);
  padding: 0.5rem 1rem;
  background-color: rgba(0,0,0,0.15);
  border: none;
  border-radius: 0.25em;
}
</style>