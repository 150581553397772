<template>
    <div class="dynamic-image">
        <picture>
            <source
                :src="responsiveImageWebP.src"
                :srcset="responsiveImageWebP.srcSet"/>
            <source
                :src="responsiveImage.src"
                :srcset="responsiveImage.srcSet"/>
            <img
                class="dynamic-image"
                :style="style"
                :class="{ 'rounded': isRounded, 'fullscreen': isFullscreen, clip }"
                :src="responsiveImage.src"
                :height="responsiveImage.height"
                :width="responsiveImage.width"
                :sizes="sizes"
                :loading="loading"
                :decoding="decoding"
                :alt="alt"/>
        </picture>
        <p v-if="description" class="tx-small tx-midgrey">{{ description }}</p>
    </div>
</template>

<script>

export default {
    name: "dynamicImage",
    props: {
        src: {
            type: String,
            required: true,
        },
        alt: {
            type: String,
            required: true,
        },
        width: {
            type: String,
            default: null,
        },
        height: {
            type: String,
            default: null,
        },
        loading: {
            type: String,
            default: "lazy",
        },
        decoding: {
            type: String,
            default: "async",
        },
        clip: {
            type: String,
            default: null,
        },
        isRounded: Boolean,
        isFullscreen: Boolean,
        backgroundColor: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            srcSizes: [400, 800, 1200, 1600, 2800],
            breakpoints: [640, 1280, 1800, 2400],
        }
    },
    computed: {
        placeholder() {
            return require(`@/assets/img/${this.src}`).placeholder;
        },
        responsiveImage() {
            return require(`@/assets/img/${this.src}`);
        },
        responsiveImageWebP() {
            return require(`@/assets/img/${this.src}?format=webp`);
        },
        sizes() {
            let sizes = "";
            for(const [index, breakpoint] of this.breakpoints.entries())
            {
                sizes += `(max-width: ${breakpoint}px) ${this.srcSizes[index]}px,`;
            }
            sizes += `${this.srcSizes[this.srcSizes.length - 1]}px`;
            return sizes;
        },
        style() {
            return this.backgroundColor
                ? `background-color: ${this.backgroundColor}`
                : `background-image: url(${this.placeholder})`;
        }
    },
}
</script>

<style lang="scss" scoped>
    @import "@/scss/_variables.scss";

    picture {
        margin: 0;
        padding: 0;
        display: flex;
    }

    .dynamic-image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-size: cover;
        overflow: hidden;

        &.rounded {
            border-radius: 0.5rem;
        }

        &.fullscreen {
            width: 100vw;
            height: 100vh;
        }
    }
</style>