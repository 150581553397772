import { createRouter, createWebHistory } from "vue-router"

import Home from "../views/Home.vue"
import About from "../views/About.vue"
import Typography from "../views/Typography.vue"
import Imprint from "../views/Imprint.vue"
import Privacy from "../views/Privacy.vue"

import AudioAlliance from "../views/projects/audio-alliance.vue"
import FreelanceJunior from "../views/projects/freelance-junior.vue"
import PotsdamCI from "../views/projects/potsdam-ci.vue"
import TobiasRunge from "../views/projects/tobias-runge.vue"
import DigitalFinance from "../views/projects/digital-finance.vue"
import ViralRender from "../views/projects/viral-render.vue"
import BreakingBreakpoints from "../views/projects/breaking-breakpoints.vue"
import IconsSoft from "../views/projects/icons-soft.vue"
import Youngry from "../views/projects/youngry.vue"


const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/imprint",
    name: "Imprint",
    component: Imprint
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy
  },
  {
    path: "/type",
    name: "Typography",
    component: Typography
  },
  {
    path: "/projects/audio-alliance",
    name: "Project Audio Alliance",
    component: AudioAlliance,
  },
  {
    path: "/projects/freelance-junior",
    name: "Project Freelance Junior",
    component: FreelanceJunior,
  },
  {
    path: "/projects/state-capital-potsdam-ci",
    name: "Project Potsdam CI",
    component: PotsdamCI,
  },
  {
    path: "/projects/tobias-runge-ci",
    name: "Project Tobias Runge",
    component: TobiasRunge,
  },
  {
    path: "/projects/digital-finance",
    name: "Project Digital Finance",
    component: DigitalFinance,
  },
  {
    path: "/projects/viral-render",
    name: "Project Invisible Bodies",
    component: ViralRender,
  },
  {
    path: "/projects/breaking-breakpoints",
    name: "Project Breaking Breakpoints",
    component: BreakingBreakpoints,
  },
  {
    path: "/projects/ios14-icons-soft",
    name: "Project Icons Soft",
    component: IconsSoft,
  },
  {
    path: "/projects/youngry",
    name: "Project Young & Hungry",
    component: Youngry,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(() => {
  window.scrollTo(0, 0);
});

export default router
