<template>
  <div class="project">
    <header>
      <mainNavigation />
    </header>

    <div class="container">
      <div class="project-grid">
        <slot name="project-head"></slot>
      </div>
    </div>
    <slot name="project-content"></slot>
  </div>
</template>

<script>
import MainNavigation from "@/components/MainNavigation";

export default {
  name: "Project",
  data() {
    return {
    }
  },
  components: {
    MainNavigation,
  },
}
</script>

<style lang="scss">
.project-grid {
  margin-bottom: 4rem;

  .project-tag-list {
    grid-column: 2;
    grid-row: 2;
  }

  .project-title {
    grid-column: 3 / span 2;
  }

  .project-intro {
    grid-column: 3 / span 2;
    grid-row: 2;
  }
}

header {
  margin-bottom: calc(5 * var(--vspace));
}

.img-wrap {
  margin: 5rem auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.img--copy {
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
  max-width: 65ch;
  margin: 3rem 0;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  overflow: hidden;

  img {
    object-fit: contain;
    width: 100%;
    height: auto;
  }
}

.img--full {
  height: 40vh;
  margin: 0 auto;
  width: 90%;
  padding: 0;
  display: flex;
  justify-content: center;

  .dynamic-image,
  img {
    object-fit: contain;
    width: 100%;
    height: auto;
  }
}

.img--max {
  width: 100vw;
  height: auto;
  object-fit: cover;
  margin: 0;
}

.img--wide {
  padding: 0;
  margin: 0;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  overflow: hidden;


  img {
    height: auto;
    max-height: 80vh;
    width: 100%;
    object-fit: contain;
  }
}

@media (min-width: 25em) {
  .img--full {
    margin: 0;
    width: 100%;
    height: 50vh;
    background-color: var(--color-base);

    .dynamic-image,
    img {
      max-width: 80em;
      width: 100%;
      height: auto;
    }
  }

  .img--cover {
    img {
      object-fit: cover;
      max-width: none;
    }
  }
}

@media (min-width: 45em) {
  .project-grid {
    display: grid;
    grid-gap: var(--vspace);
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 10vh;
  }

  .project-title {
    grid-column: 3 / span 2;
  }

  .project-intro {
    grid-column: 3 / span 2
  }

  .project-tag-list {
    grid-column: 2;
    grid-row: 2;
  }
}

@media (min-width: 50em) {
  .img--full {
    margin: 0;
    width: 100%;
    height: 100vh;
  }
}

</style>
