<template>
  <div id="gradient" class="position-absolute overflow-hidden">
      <svg  viewBox="0 0 1000 1000"
            preserveAspectRatio="xMidYMid slice"
            class="flex-shrink-0"
            style="min-width:100%;
                   min-height:100%;
                   filter:saturate(150%);
                   -webkit-filter:saturate(150%)"
      >
        <defs>
          <radialGradient v-for="(color, index) in colors" :key="index" :id="`rg${index}`" :fx="getRandomFocus()" :fy="getRandomFocus()">
            <stop offset="0%"   :stop-color="color" stop-opacity="100"></stop>
            <stop offset="100%" :stop-color="color" stop-opacity="0"></stop>
          </radialGradient>
        </defs>

        <rect id="bg" x="0" y="0" width="100%" height="100%"></rect>
        <template v-for="n in 10" :key="n">
          <circle class="circle" :class="getCircleClass(n)" cx="0" cy="0" :r="getRandomRadius()" :transform="getRandomTransform()" />
        </template>
      </svg>
    </div>
</template>

<script>
export default {
    name: "FxGradient",
    data() {
      return {
        seed: 250,
        colors: [
            "hsl(260, 80%, 25%)",
            "hsl(240, 80%, 60%)",
            "hsl(350, 80%, 60%)",
            "hsl(250, 100%, 75%)",
        ],
      };
    },
    methods: {
      getRandomTransform() {
          const scale  = `scale(${Math.random()+ 0.25} ${Math.random()+0.25})`;
          const skewX  = `skewX(${Math.random()* 60 - 50})`;
          const rotate = `rotate(${Math.floor(Math.random() * 20)})`;
          const trans  = `translate(${Math.random() * 500 - 0} ${Math.random() * 800 - 0})`;
          return `translate(1000 1000) ${scale} ${skewX} ${rotate} ${trans} translate(-1000 -1000)`;
      },
      getRandomFocus() {
          return Math.random() * 0.5 + 0.05;
      },
      getRandomRadius() {
          return Math.random() * 500 + 250;
      },
      getCircleClass(n) {
          const circleClass = [2,1,0,3,0,1,2,3,3,2,0];
          return `circle${circleClass[n]}`;
      },
      easeInOutQuint(t) {
        return t <.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t;
      }
    },
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

#gradient {
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
}

#bg {
    fill: var(--color-blackgrey);
}

@for $i from 0 through 3 {
  .circle#{$i} {
    fill: url(#rg#{$i});
  }
}

.circle {
  // will-change: transform, opacity;
  // animation: fadeIn 3s ease-in-out forwards;
}

@keyframes fadeIn {
  0%   { opacity: 0.5; }
  100% { opacity: 1; }
}
</style>