<template>
    <project>
        <template v-slot:project-head>
            <h2 class="project-title">Soft Icons</h2>
            <div class="project-intro">
                <p>
                    The release of iOS 14 allowed for custom icons to shortcuts, which became a popular design playground.
                    I’ve created a simple icon library with a set of 300 background layouts.
                </p>
                <a class="btn" href="https://topada.gumroad.com/l/nqjBD" no-refer>Open Gumroad</a>
            </div>
            <ul class="project-tag-list tx-midgrey tx-small">
                <li>Icon Design</li>
                <li>Side Project</li>
            </ul>
        </template>
        <template v-slot:project-content>
            <dynamic-image
                src="projects/soft-icons/si-1.png"
                alt="Audio Alliance Cover"
                loading="lazy"
                decoding="async"/>
            <div class="content-grid">
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/soft-icons/si-2.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/soft-icons/si-3.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/soft-icons/si-4.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/soft-icons/si-5.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
            </div>
        </template>
    </project>
</template>

<script>
import Project from "@/layouts/Project.vue";
import DynamicImage from "@/components/DynamicImage";

export default {
    name: "icons-soft",
    components: {
        Project,
        DynamicImage,
    }
}
</script>

<style lang="scss" scoped>
    .content-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        max-width: 120rem;
        width: 90%;
        margin: 4rem auto;
    }

    .block {
        grid-column: 1 / -1;
    }

    @media (min-width: 46rem) {
        .content-grid {
            grid-template-columns: repeat(4, 1fr);
            grid-template-areas:    "b1 b1 b1 b1"
                                    "b2 b2 b2 b2"
                                    "b3 b3 b3 b3"
                                    "b4 b4 b4 b4"
                                    "b5 b5 b5 b5";
        }

        @for $i from 1 through 50 {
            .block:nth-child(#{$i}) {
                grid-area: b#{$i};
            }
        }
    }

    @media (min-width: 80rem) {
        .content-grid {
            grid-template-columns: repeat(6, 1fr);
            gap: 4rem;
            padding: 4rem;
            grid-template-areas:    "b1 b1 b1 b2 b2 b2"
                                    "b3 b3 b3 b4 b4 b4"
                                    "b5 b5 b5 b5 b5 b5";
        }
    }

    .btn {
        background-color: var(--color-darkgrey-200);
        border-radius: 2rem;
        display: inline-flex;
        padding: 0.75rem 2rem;
        transition: all 0.2s ease-in-out;
        font-size: var(--tx-clamp-small);
        font-weight: 500;
        letter-spacing: 0.025rem;

        &:hover {
            background-color: var(--color-darkgrey-300);
        }
    }
</style>