<template>
    <div class="container nav-container">
        <nav
            class="nav"
            :class="{
                'nav--scroll-fixed' : isScrollFixed,
                'nav--fadeIn' : isScrollFixed && isScrollingUp && isBelowFold,
            }">
            <div class="nav-row">
                <div class="nav-brand tx-white align-center">
                    <router-link to="/">topada</router-link>
                </div>
                <ul class="nav-links d-flex flex-row justify-content-between">
                <li><router-link :to="{ name: 'Home' }">works</router-link></li>
                <li><router-link :to="{ name: 'About' }">about</router-link></li>
                <li><a href="mailto:jonas@topada.de" target="_blank">contact</a></li>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
    export default {
        name: "MainNavigation",
        props: {
            isScrollFixed: Boolean,
            isScrollingUp: Boolean,
            isBelowFold: Boolean,
        },
    }
</script>

<style lang="scss" scoped>
    .nav-container {
        position: relative;
    }

    .nav {
        transition: all 0.5s ease-in-out;
        border-radius: 2rem;
        overflow: hidden;

        .nav-row {
            padding: 1.5rem 0;
            backdrop-filter: opacity(0);
        }

        &--scroll-fixed {
            position: fixed;
            bottom: -5rem;
            left: 0;
            right: 0;
            z-index: 100;
            width: calc(100vw - 2rem);
            margin: 0 auto;
            max-width: inherit;

            .nav-row {
                backdrop-filter: blur(1.5rem) brightness(0.85) saturate(150%) opacity(1);
                background-color: hsla(240, 40%, 80%, 0.25);
                padding: 0.25rem 1rem;
                padding-right: 1rem;
            }
        }

        &--fadeIn {
            bottom: 1rem;
        }
    }

    @media (min-width: 50rem) {
        .nav {
            margin: 0 auto;

            &--scroll-fixed {
                transform: translateY(-5rem);
                position: fixed;
                top: 1rem;
                bottom: auto;
                width: inherit;

                .nav-row {
                    padding: 0.5rem 1.5rem;
                    padding-right: 0.5rem;
                }
            }

            &--fadeIn {
                top: 6rem;
            }
        }
    }

    py-50 {
        padding: 1rem 0;
    }

    .my-50 {
        margin: 0.5rem 0;
    }
</style>