<template>
    <project>
        <template v-slot:project-head>
            <h2 class="project-title">Invisible Bodies</h2>
            <div class="project-intro">
                <p>
                    Designing simplified 3D viral shapes as key visuals for digital and print publications for World leaderin Viral Vector technologies <a class="hyperlink" href="https://www.sirion-biotech.com/">Sirion Biotech</a>.
                </p>
                <a class="btn" href="https://topada.gumroad.com/l/nqjBD" no-refer>Open Sirion Biotech</a>
            </div>
            <ul class="project-tag-list tx-midgrey tx-small">
                <li>3D Rendering</li>
                <li>Generative Design</li>
            </ul>
        </template>
        <template v-slot:project-content>
                <dynamic-image
                    is-fullscreen
                    src="projects/invisible-bodies/virus-1.jpg"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
            <div class="content-grid">
                <div class="block text-block">
                    <h2 class="tx-white">Adenovirus, Lentivirus & Adeno-associated Virus</h2>
                    <p>
                        Initially I created an adenovirus for a international advertising campaign.
                        Designing viral shapes offered a great insight into 3D Modeling, Lighting and Texturing.
                    </p>
                </div>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/invisible-bodies/virus-2.jpg"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/invisible-bodies/virus-3.jpg"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/invisible-bodies/virus-5.jpg"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/invisible-bodies/virus-6.jpg"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/invisible-bodies/virus-7.jpg"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/invisible-bodies/virus-8.jpg"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/invisible-bodies/virus-9.jpg"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <dynamic-image
                    is-fullscreen
                    class="block"
                    is-rounded
                    src="projects/invisible-bodies/virus-10.jpg"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <div class="block text-block">
                    <h2 class="tx-white">Premium Viruses</h2>
                    <p>
                        As a fun side project, I further experimented with the viral shapes and explored high-value materials.
                    </p>
                </div>
                <dynamic-image
                    is-fullscreen
                    class="block"
                    is-rounded
                    src="projects/invisible-bodies/gold-1.jpg"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <dynamic-image
                    is-fullscreen
                    class="block"
                    is-rounded
                    src="projects/invisible-bodies/gold-2.jpg"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <dynamic-image
                    is-fullscreen
                    class="block"
                    is-rounded
                    src="projects/invisible-bodies/gold-3.jpg"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
            </div>
        </template>
    </project>
</template>

<script>
import Project from "@/layouts/Project.vue";
import DynamicImage from "@/components/DynamicImage";

export default {
    name: "viral-render",
    components: {
        Project,
        DynamicImage,
    }
}
</script>

<style lang="scss" scoped>
    .content-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        max-width: 120rem;
        width: 90%;
        margin: 4rem auto;
    }

    .block {
        grid-column: 1 / -1;
    }

    @media (min-width: 46rem) {
        .content-grid {
            grid-template-columns: repeat(4, 1fr);
            grid-template-areas:    "b4 b4 b4 b4"
                                    "b1 b1 b1 b1"
                                    "b2 b2 b2 b2"
                                    "b3 b3 b3 b3"
                                    "b5 b5 b5 b5"
                                    "b6 b6 b6 b6"
                                    "b7 b7 b7 b7"
                                    "b8 b8 b8 b8"
                                    "b9 b9 b9 b9"
                                    ". b10 b10 ."
                                    "b11 b11 b11 b11"
                                    "b12 b12 b12 b12"
                                    "b13 b13 b13 b13";
        }

        @for $i from 1 through 50 {
            .block:nth-child(#{$i}) {
                grid-area: b#{$i};
            }
        }
    }

    @media (min-width: 80rem) {
        .content-grid {
            grid-template-columns: repeat(6, 1fr);
            gap: 4rem;
            padding: 4rem;
            grid-template-areas:    "b1 b1 b1 b5 b5 b5"
                                    "b2 b2 b2 b3 b3 b3"
                                    "b4 b4 b4 b4 b4 b4"
                                    "b6 b6 b6 b6 b6 b6"
                                    "b8 b8 b8 b8 b7 b7"
                                    "b9 b9 b9 b9 b9 b9"
                                    "b10 b10 . . . . "
                                    "b11 b11 b11 b12 b12 b12"
                                    "b13 b13 b13 b13 b13 b13";
        }
    }

    .btn {
        background-color: var(--color-darkgrey-200);
        border-radius: 2rem;
        display: inline-flex;
        padding: 0.75rem 2rem;
        transition: all 0.2s ease-in-out;
        font-size: var(--tx-clamp-small);
        font-weight: 500;
        letter-spacing: 0.025rem;

        &:hover {
            background-color: var(--color-darkgrey-300);
        }
    }
</style>