<template>
    <project>
        <template v-slot:project-head>
            <h2 class="project-title">Digital Finance</h2>
            <div class="project-intro">
                <p>
                    Designing complex onboarding user flows and asset management dashboards with regulatory constraints, as well as design systems
                    for selected clients of the fintech service provider <a class="hyperlink" href="https://www.fintechcube.com/de/home" rel="noopener" target="_blank">fintechcube</a>.
                </p>
            </div>
            <ul class="project-tag-list tx-midgrey tx-small">
                <li>Interaction Design</li>
                <li>Interface Design</li>
                <li>Experience Design</li>
                <li>Design Systems</li>
            </ul>
        </template>
        <template v-slot:project-content>
            <figure class="img--full" style="background-color: #C7CED1">
                <dynamic-image
                    is-fullscreen
                    src="digital-finance-cover.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
            </figure>
            <div class="content-grid">
                 <div class="block text-block">
                    <h2 class="tx-white">Thomas Lloyd</h2>
                    <p>
                        For the european asset management Thomas Lloyd, I designed a responsive design system with flexible components
                        and layouts, that could easily be translated into various languages. Based on the brand logo, I’ve developed
                        a unique icon style for key visuals in the onboarding process.
                    </p>
                </div>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/digital-finance/thomas-lloyd/tl-1.png"
                    alt="Audio Alliance Cover"
                    description="Exploration of different icon style variants"
                    loading="lazy"
                    decoding="async"/>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/digital-finance/thomas-lloyd/tl-2.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/digital-finance/thomas-lloyd/tl-3.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <div class="block text-block">
                    <h2 class="tx-white">Digitalization of Client Onboarding & Client Dashboards</h2>
                    <p>
                        A streamlined onboarding system enabled the clients to provide all required data and documents online.
                        While traditional asset managements only provided annual or monthly reports at that time, we enabled clients
                        to analyse their portfolio with exclusive client dashboards.
                    </p>
                </div>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/digital-finance/asset-management/am-1.png"
                    alt="Audio Alliance Cover"
                    description="Concept for a client dashboard"
                    loading="lazy"
                    decoding="async"/>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/digital-finance/asset-management/am-2.png"
                    alt="Audio Alliance Cover"
                    description="Concept for a white label asset management solution"
                    loading="lazy"
                    decoding="async"/>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/digital-finance/asset-management/am-3.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
                <dynamic-image
                    class="block"
                    is-rounded
                    src="projects/digital-finance/asset-management/am-4.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
            </div>
        </template>
    </project>
</template>

<script>
import Project from "../../layouts/Project.vue";
import DynamicImage from "@/components/DynamicImage";

export default {
    name: "digital-finance",
    components: {
        Project,
        DynamicImage,
    }
}
</script>

<style lang="scss" scoped>
    .content-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        max-width: 120rem;
        width: 90%;
        margin: 4rem auto;
    }

    .block {
        grid-column: 1 / -1;
    }

    @media (min-width: 46rem) {
        .content-grid {
            grid-template-columns: repeat(4, 1fr);
            grid-template-areas:    "b1 b1 b2 b2"
                                    "b3 b3 b4 b4"
                                    ". b5 b5 . "
                                    "b7 b7 b8 b8"
                                    "b6 b6 b9 b9";
        }

        @for $i from 1 through 50 {
            .block:nth-child(#{$i}) {
                grid-area: b#{$i};
            }
        }
    }

    @media (min-width: 80rem) {
        .content-grid {
            grid-template-columns: repeat(6, 1fr);
            gap: 4rem;
            padding: 4rem;
            grid-template-areas:    "b1 b1 b2 b2 b2 b2"
                                    "b3 b3 b3 b4 b4 b4"
                                    "b5 b5 b7 b7 b7 b7"
                                    "b8 b8 b8 b8 b8 b8"
                                    "b6 b6 b6 b9 b9 b9";
        }
    }
</style>