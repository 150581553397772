<template>
  <header>
    <fx-gradient class="fade-in"/>
    <div class="d-flex flex-column justify-content-between h-100">
      <mainNavigation />
      <div class="home-container container d-flex h-100">
        <div class="phrase-w">
          <div class="word-w" v-for="word in splitter(header)" :key="word">
            <h1 class="tx-display tx-white word">
              {{ word }}
            </h1>
          </div>
        </div>
      </div>
    </div>
  </header>

  <section class="container project-grid my-6">

    <div class="project-grid__item" v-for="(project, index) in projects" :key="index">
      <router-link :to="`/projects/${project.slug}`" class="d-block">
        <div class="project__image rounded-small overflow-hidden">
          <figure>
            <dynamic-image
              :src="project.img.src"
              :alt="project.img.alt"
              loading="lazy"
              decoding="async"/>
          </figure>
        </div>
        <div class="project__meta">
          <h3>{{project.title}}</h3>
          <p>{{project.description}}</p>
          <p class="tx-small tx-midgrey">{{project.date}}</p>
        </div>
      </router-link>
    </div>

  </section>

  <main-footer />
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

import FxGradient from "@/components/FxGradient2";
import MainNavigation from "@/components/MainNavigation";
import MainFooter from "@/components/MainFooter";
import DynamicImage from "@/components/DynamicImage";

export default {
  name: 'Home',
  components: {
    FxGradient,
    MainNavigation,
    MainFooter,
    DynamicImage,
  },
  data() {
    return {
      header: "",
      projects: [
        {
          title: "Audio Alliance",
          slug: "audio-alliance",
          description: "Designing Covers for thrilling podcasts",
          date: "2020—2021",
          img: {
            src: "audio-alliance-cover_v2.png",
            alt: "Audio Alliance",
          },
        },
        {
          title: "Freelance Junior",
          slug: "freelance-junior",
          description: "Connecting young aspiring freelancers with clients",
          date: "2019—",
          img: {
            src: "freelance-junior-cover_v5.png",
            alt: "Freelance Junior",
          },
        },
        {
          title: "Digital Finance",
          slug: "digital-finance",
          description: "Empowering selected customers to manage their assets online",
          date: "2017—2019",
          img: {
            src: "digital-finance-cover.png",
            alt: "Digital Finance",
          },
        },
        {
          title: "Tobias Runge",
          slug: "tobias-runge-ci",
          description: "Establishing a unique appearance for an aspiring carpenter artist",
          date: "Spring 2020",
          img: {
            src: "tobias-runge-cover.png",
            alt: "Tobias Runge",
          },
        },
        {
          title: "State Capital Potsdam",
          slug: "state-capital-potsdam-ci",
          description: "Reintroducting the heraldic eagle as a key identifier",
          date: "Autumn 2018",
          img: {
            src: "potsdam-cover_v2.png",
            alt: "Potsdam Eagle",
          },
        },
        {
          title: "Invisible Bodies",
          slug: "viral-render",
          description: "3D Rendering of multiple viruses for a biotech company",
          date: "2018",
          img: {
            src: "adenovirus-cover.png",
            alt: "Adenovirus 3d render",
          },
        },
        {
          title: "Breaking Breakpoints",
          slug: "breaking-breakpoints",
          description: "Modern Web Design beyond utilitarian constraints",
          date: "2019—2020",
          img: {
            src: "breaking-breakpoints-cover.png",
            alt: "Breaking Breakpoints",
          },
        },
        {
          title: "Soft Icons",
          slug: "ios14-icons-soft",
          description: "Handcrafting soft icon set for iOS 14",
          date: "Autumn 2020",
          img: {
            src: "icon-set-cover.png",
            alt: "Soft Icons on iPhones",
          },
        },
        {
          title: "Young & Hungry",
          slug: "youngry",
          description: "Design for a freelance collective agency",
          date: "Summer 2020",
          img: {
            src: "young-and-hungry-cover.png",
            alt: "Young and Hungry Agency Landing Page",
          },
        },
      ],
    }
  },
  mounted() {
    console.log("mounted");
    this.onLoad();
  },
  beforeUnmount() {
    console.log("beforeUnmount");
  },
  updated() {
    console.log("updated");
  },
  methods:
  {
    onLoad()
    {
      this.header = "Jonas Köpfer is a Designer and Developer focused on delightful Interaction Design, working as a freelancer in Frankfurt am Main.";
      window.requestAnimationFrame(() => {
        this.headerAnimation();

        if(!this.$isMobile())
        {
          this.addScrollTrigger();
        }
      });
    },
    addScrollTrigger()
    {
      gsap.set(".project-grid__item", {
        opacity: 0,
        y: "2.5rem",
      });

      ScrollTrigger.batch(".project-grid__item", {
        onEnter: batch => gsap.to(batch, {
            opacity: 1,
            y: 0,
            stagger: 0.5,
            duration: 2,
            ease:"power3.out",
            overwrite: true,
          }
        ),
        onLeaveBack: batch => gsap.set(batch, {
            opacity: 0,
            y: "2.5rem",
            overwrite: true
          }
        ),
      });

      ScrollTrigger.refresh();
    },
    headerAnimation()
    {
      gsap.from(".word", {
        duration: 2,
        opacity: 0,
        stagger: 0.08,
        y: "100%",
        rotation: 5,
        transformOrigin: "left 100%",
        ease:"power3.inOut"
      });
    },
    splitter(str)
    {
      return str.split(" ");
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_animations.scss";

* {
  -webkit-backface-visibility: hidden;
}


header {
  height: 100vh;
  position: relative;
  color: white;
  background-color: transparent;
  transform: translate3d(0,0,0);
}

.tx-uppercase {
  text-transform: uppercase;
}

.home-container {
  align-items: center;
}

.project-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ($spacer * 6) ($spacer * 3);
  grid-auto-flow: dense;
  content-visibility: auto;

  &__item {
    grid-column: span 5;
    will-change: transform, opacity;

    figure{
      position: relative;

      &::after {
        opacity: 0;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        content: "";
        transition: opacity 0.5s var(--ease-out-quad) 0.05s;
        background: var(--color-accent);
        mix-blend-mode: screen;
        will-change: opacity;
      }
    }

    &:hover {

      figure {
        transform: scale(1.05);

        &::after {
          opacity: 1;
        }
      }

      img {
        filter: saturate(0) brightness(0.85) contrast(1.25);
      }

      h2 {
        color: var(--color-accent);
      }
    }

    figure,
    img,
    h2 {
      transition: all 0.5s var(--ease-out-quad) 0.05s;
    }

    .project__image {
      background-clip: padding-box;
      margin: 0;
      padding: 0;

      margin-bottom: 1.25rem;

      figure {
        margin: 0;
        padding: 0;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    .project__meta {
      h1, h2, h3, p {
        margin: 0;
      }
    }
  }
}

.phrase-w {
  display: flex;
  flex-wrap: wrap;
  max-width: 70rem;
}

.word-w {
  margin-right: 1ch;
  overflow: hidden;
}

.word {
  transform: translate(0, -0.2rem);
  will-change: transform, opacity;
}

/* xs */
@media (max-width: 25em) {
}

/* sm */
@media (min-width: 25em) {
  header {
    height: 80vh;
    max-height: 50em;
  }
  .project-grid {
    grid-template-columns: repeat(auto-fit, minmax(4em, 1fr));
  }
}

/* md */
@media (min-width: 50em) {
  header {
    height: 61.8vh;
    max-height: none;
  }

  .project-grid {
    &__item {
      opacity: 0;
    }

    &__item:nth-child(2n) {
      grid-column: span 10;
    }

    &__item:nth-child(3n) {
      grid-column: span 10;
    }

    &__item:nth-child(4n) {
      grid-column: span 5;
    }

    // &__item:nth-child(6n) {
    //   grid-column: span 3;
    // }
  }
}

@media (min-width: 100rem) {
  .project-grid {
    grid-gap: ($spacer * 8) ($spacer * 3);

    &__item:nth-child(1n) {
      grid-column: span 6;
    }

    &__item:nth-child(2n) {
      grid-column: span 8;
    }

    &__item:nth-child(3n) {
      grid-column: span 4;
    }

    &__item:nth-child(4n) {
      grid-column: span 4;
    }

    &__item:nth-child(5n) {
      grid-column: span 6;
    }

  }
}
</style>