<!-- eslint-disable no-irregular-whitespace -->
<template>
    <project>
        <template v-slot:project-head>
            <h2 class="project-title">Freelance Junior</h2>
            <div class="project-intro">
                <p>Freelance Junior is a job platform that connects young professional freelancers with businesses. I collaborated with the startup to redesign their platforms user experience and user interface.</p>
                <p>Introducing a design system with well defined components enabled the team to iterate on new prototypes faster and bridge to gap between design and development.</p>
                <a class="btn" href="https://freelancejunior.de?ref=topada" no-refer>Open Freelance Junior</a>
            </div>
            <ul class="project-tag-list tx-midgrey tx-small">
                <li>Web Design</li>
                <li>Web Development</li>
                <li>Visual Identity</li>
                <li>Interface Design</li>
                <li>Experience Design</li>
                <li>Design System</li>
            </ul>
        </template>
        <template v-slot:project-content>
            <figure class="img--max">
                <dynamic-image
                    src="freelance-junior-cover_v5.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
            </figure>
            <div class="container">
                <div class="content-grid my-500">
                    <div class="center">
                        <p>
                            Since the beginning of my collaboration with Freelance Junior in early 2018,
                            I've constantly challenged, how we might tailor a professional, yet friendly brand experience
                            for aspiring freelancers as well as high-profile clients.
                        </p>
                        <p>
                            With the help of personas and user journeys, I idenitified and helped streamline core interactions: I unified the signup and login process and
                            divided an extensive onboarding process into managable and focused steps.
                        </p>
                    </div>
                </div>
            </div>
            <figure class="img--wide py-400" style="background-color: hsl(196,10%, 55%)">
                <dynamic-image
                    is-rounded
                    src="projects/freelance-junior/freelance-junior-02.png"
                    alt="Audio Alliance Cover"
                    loading="lazy"
                    decoding="async"/>
            </figure>
            <div class="container">
                <div class="content-grid my-500">
                    <div class="center">
                        <h3>Design System</h3>
                        <p>
                            I lay the foundations for a design system including a UI library, designed in figma and developed in Vue.js. The system enabled us to quickly
                            iterate on new concepts and develop new features such as payment processing, instant messaging, and client–freelancer management.
                        </p>
                    </div>
                </div>
            </div>

            <figure class="img--wide">
                <dynamic-image
                    is-rounded
                    src="projects/freelance-junior/freelance-junior-01.png"
                    alt="Freelance Junior"
                    loading="lazy"
                    decoding="async"/>
            </figure>

            <div class="container">
                <div class="content-grid my-500">
                    <div class="center">
                        <p>
                            I turned a bland company blog into the GREATJOB magazine, freelancers love reading. The magazine today is well visited and a great channel to recruite newcomers and interested clients.
                        </p>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="center">
                    <figure class="img--wide">
                            <dynamic-image
                                is-rounded
                                src="projects/freelance-junior/freelance-junior-03.png"
                                alt="Freelance Junior"
                                loading="lazy"
                                decoding="async"/>
                        </figure>
                </div>
            </div>

            <div class="container">
                <div class="content-grid my-500">
                    <div class="center">
                        <p>
                            The freelancer profiles, organically grown with good intentions, had turned into a cluttered mix of incoherent user interface elements.
                            I completely redesigned and developed the profiles and transformed them into personal spaces, where freelancers can now express their individiual strengths.
                        </p>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="content-grid my-500">
                    <div class="center">
                        <div class="notebook">
                            <dynamic-image
                                is-rounded
                                class="notebook__body"
                                src="mockup/mockup-macbook.png"
                                alt="Audio Alliance Cover"
                                loading="lazy"
                                decoding="async"/>
                            <video
                                class="video notebook__screen"
                                loop
                                muted
                                autoplay
                                webkit-playsinline
                                playsinline
                                width="960"
                                height="600">
                                <source :src="require(`@/assets/vid/topada-freelance-profile.mp4`)" type="video/mp4">
                                Your browser does not support the video element. Kindly update it to latest version.
                            </video >
                        </div>
                    </div>
                </div>
            </div>



            <!-- <figure class="img--full" style="background-color: hsl(195, 40%, 15%)">
                <img src="/assets/projects/freelance-junior/freelance-junior-0.png" />
            </figure>
            <figure class="img--full" style="background-color: hsl(196,10%, 55%)">
                <img src="/assets/projects/freelance-junior/freelance-junior-1.png" />
            </figure> -->
        </template>
    </project>
</template>

<script>
import project from "../../layouts/Project.vue";
import DynamicImage from "@/components/DynamicImage";


export default {
    name: "freelance-junior",
    components: {
         project,
         DynamicImage,
    }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

  .grid {
    display: grid;
    grid-gap: var(--vspace);
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 10vh;
  }

  .py-400 {
      padding: 8rem 2rem;
  }

  .my-500 {
      margin: 10vh 0;
  }

  @media (min-width: 70em) {
    .content-grid {
        display: grid;
        grid-gap: var(--vspace);
        margin-bottom: 10vh;
        grid-template-columns: repeat(4, 1fr);
    }

    .center {
        grid-column: 2 / 4;
    }
  }

  .video {
      width: 100%;
      height: auto;
  }

  .notebook {
      position: relative;

      &__body {
          width: 100%;
          height: auto;
      }

      &__screen {
          border-radius: 0.25rem;
          position: absolute;
          left: 10%;
          top: 5%;
          width: 80%;
      }
  }

  @media (min-width: 50rem) {
      .notebook {
          width: 150%;
        margin-left: -25%;
      }
  }

.btn {
    background-color: var(--color-darkgrey-200);
    border-radius: 2rem;
    display: inline-flex;
    padding: 0.75rem 2rem;
    transition: all 0.2s ease-in-out;
    font-size: var(--tx-clamp-small);
    font-weight: 500;
    letter-spacing: 0.025rem;

    &:hover {
        background-color: var(--color-darkgrey-300);
    }
}
</style>